import { useMemo } from 'react'
import _ from 'lodash'

/**
 * Compose user initial values based on user, public professions, and HCP professions.
 * @param {Object} user - The user object containing user details.
 * @param {Array} publicProfessions - List of public professions.
 * @param {Array} hcpProfessions - List of HCP professions.
 * @returns {Object} - The composed initial user values for the form.
 */
const useUserInitialValues = ({ user, publicProfessions, hcpProfessions }) => {
  const userInitialValues = useMemo(() => {
    const userProfessionId = _.get(user, 'professions[0].id');
    const hcpProfession = _.find(hcpProfessions, (p) => p.value === userProfessionId)
    const publicProfession = _.find(publicProfessions, (p) => p.value === userProfessionId)
    const profession = hcpProfession || publicProfession

    return {
      title: _.get(user, 'title'),
      firstName: _.get(user, 'firstName'),
      lastName: _.get(user, 'lastName'),
      email: _.get(user, 'email'),
      occupation: profession ? (hcpProfession ? 'hcp' : 'public') : undefined,
      hcpProfessionId: hcpProfession ? userProfessionId : undefined,
      publicProfessionId: publicProfession ? userProfessionId : undefined,
      company: _.get(user, 'company'),
      houseNumberOrName: _.get(user, 'houseNumberOrName'),
      address: _.get(user, 'address'),
      addressLineTwo: _.get(user, 'addressLineTwo'),
      city: _.get(user, 'city'),
      county: _.get(user, 'county'),
      postalCode: _.get(user, 'postalCode'),
      telephoneCountryCode: _.get(user, 'telephoneCountryCode'),
      telephone: _.get(user, 'telephone'),
      telephoneExtension: _.get(user, 'telephoneExtension'),
      smsCommunicationsPermitted: _.get(user, 'smsCommunicationsPermitted') ? ['on'] : '',
      emailCommunicationsPermitted: _.get(user, 'emailCommunicationsPermitted') ? ['on'] : '',
      organisations: _.get(user, 'organisations', [])
    };
  }, [user, publicProfessions, hcpProfessions])

  return userInitialValues
}

export default useUserInitialValues
